// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cd-js": () => import("./../../../src/pages/cd.js" /* webpackChunkName: "component---src-pages-cd-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-2-5-d-unity-prototype-js": () => import("./../../../src/pages/projects/2_5d-unity-prototype.js" /* webpackChunkName: "component---src-pages-projects-2-5-d-unity-prototype-js" */),
  "component---src-pages-projects-3-d-printer-dashboard-js": () => import("./../../../src/pages/projects/3d-printer-dashboard.js" /* webpackChunkName: "component---src-pages-projects-3-d-printer-dashboard-js" */),
  "component---src-pages-projects-birds-js": () => import("./../../../src/pages/projects/birds.js" /* webpackChunkName: "component---src-pages-projects-birds-js" */),
  "component---src-pages-projects-dartscore-js": () => import("./../../../src/pages/projects/dartscore.js" /* webpackChunkName: "component---src-pages-projects-dartscore-js" */),
  "component---src-pages-projects-dreamventure-js": () => import("./../../../src/pages/projects/dreamventure.js" /* webpackChunkName: "component---src-pages-projects-dreamventure-js" */),
  "component---src-pages-projects-eterna-js": () => import("./../../../src/pages/projects/eterna.js" /* webpackChunkName: "component---src-pages-projects-eterna-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-phantasma-js": () => import("./../../../src/pages/projects/phantasma.js" /* webpackChunkName: "component---src-pages-projects-phantasma-js" */),
  "component---src-pages-projects-ricosoftworks-js": () => import("./../../../src/pages/projects/ricosoftworks.js" /* webpackChunkName: "component---src-pages-projects-ricosoftworks-js" */),
  "component---src-pages-projects-vibrant-venture-js": () => import("./../../../src/pages/projects/vibrant-venture.js" /* webpackChunkName: "component---src-pages-projects-vibrant-venture-js" */)
}

